/** @format */

import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

// Array of image URLs
const imageUrls = [
  "https://i.ibb.co/Vv5rkDK/api-partner-verzon.png",
  "https://i.ibb.co/J399KDg/api-partner-netflix.png",
  "https://i.ibb.co/TKT0F5L/api-partner-yelp.png",
  "https://i.ibb.co/HG6KR89/api-partner-adobe.png",
  "https://i.ibb.co/hMSJ1sg/api-partner-ring.png",
  "https://i.ibb.co/4RWQcMS/api-partner-nespresso.png",
];

const Trusted = () => {
  return (
    <Box
      sx={{
        py: 5,
        backgroundColor: "#f9f9f9",
        overflow: "hidden",
        fontFamily: "Poppins",
      }}>
      <Typography variant="h3" fontWeight="bold" align="center" gutterBottom>
        Trusted By 1000+ Companies
      </Typography>
      <LogoSlider>
        <LogoSlide>
          {imageUrls.concat(imageUrls).map((url, index) => (
            <img key={index} src={url} alt={`Brand ${index + 1}`} />
          ))}
        </LogoSlide>
      </LogoSlider>
    </Box>
  );
};

// Styled components
const LogoSlider = styled(Box)({
  position: "relative",
  overflow: "hidden",
  padding: "30px 0",
  whiteSpace: "nowrap",
  "&:hover .logos-slide": {
    animationPlayState: "paused",
  },
});

const LogoSlide = styled(Box)({
  display: "inline-block",
  whiteSpace: "nowrap",
  animation: "slide 45s infinite linear",
  "& img": {
    width: "183px",
    height: "83px",
    margin: "0 40px",
  },
});

const keyframes = `
@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
`;

// Injecting the keyframes into the document head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = keyframes;
document.head.appendChild(styleSheet);

export default Trusted;
