/** @format */

import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Badge,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import { ShoppingCart, Menu as MenuIcon } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { useCart } from "../CartContext"; // Import useCart from CartContext
import Nav from "./Nav"; // Import the Nav component

const StyledAppBar = styled(AppBar)({
  backgroundColor: "#FFF",
});

const StyledNavLink = styled(NavLink)({
  color: "#000",
  textDecoration: "none",
  marginRight: "20px",
  "&:hover": {
    textDecoration: "underline",
  },
  "&.active": {
    fontWeight: "bold",
  },
});

const Logo = styled(Typography)({
  fontWeight: "bold",
  color: "#000",
  flexGrow: 1,
});

export default function Header() {
  const { cartItems } = useCart(); // Use CartContext to get cart items
  const [drawerOpen, setDrawerOpen] = useState(false); // State to manage mobile drawer

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <StyledAppBar position="static">
      <Toolbar>
        <Logo variant="h6">
          Side Car <span style={{ color: "#6a85ff" }}> Mirror </span>
        </Logo>

        {/* Mobile Menu Icon */}
        <Box sx={{ display: { xs: "block", sm: "none" } }}>
          <IconButton
            color="inherit"
            aria-label="menu"
            sx={{ color: "#000" }}
            onClick={handleDrawerOpen}>
            <MenuIcon />
          </IconButton>
        </Box>

        {/* Desktop Navigation Links */}
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <StyledNavLink to="/" end>
            Home
          </StyledNavLink>
          <StyledNavLink to="/shop">Products</StyledNavLink>
          <StyledNavLink to="/about">About</StyledNavLink>
          <StyledNavLink to="/contact">Contact</StyledNavLink>
        </Box>

        {/* Cart Icon */}
        <IconButton
          color="inherit"
          aria-label="cart"
          sx={{ color: "#000" }}
          component={NavLink}
          to="/cart">
          <Badge badgeContent={cartItems.length} color="secondary">
            <ShoppingCart />
          </Badge>
        </IconButton>
      </Toolbar>

      {/* Mobile Drawer with Navigation Links */}
      <Nav open={drawerOpen} onClose={handleDrawerClose} />
    </StyledAppBar>
  );
}
