/** @format */

import React from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  TextField,
  Grid,
  Divider,
} from "@mui/material";
import { NavLink } from "react-router-dom"; // Import NavLink
import {
  Facebook,
  Twitter,
  Instagram,
  YouTube,
  Mail,
} from "@mui/icons-material";
import { styled } from "@mui/system";

// import Logo from "../../assets/imgs/logo.png"; // Update with the path to your logo image

export default function Footer() {
  const Logo = styled(Typography)({
    fontWeight: "bold",
    color: "#fff",
    flexGrow: 1,
  });
  return (
    <footer
      style={{ backgroundColor: "#000", color: "#fff", padding: "2rem 2rem " }}>
      <Grid container spacing={4}>
        {/* Company Info */}
        <Grid item xs={12} md={3}>
          <Logo variant="h5">
            Side Car <span style={{ color: "#6a85ff" }}> Mirror </span>
          </Logo>
          <Typography variant="p" paragraph>
            Your one-stop shop for high-quality auto parts and accessories.
            Empowering car enthusiasts since 2005.
          </Typography>
          <Box display="flex" gap={2}>
            <NavLink to="#" style={{ color: "inherit" }}>
              <Facebook sx={{ fontSize: 20, color: "#fff" }} />
            </NavLink>
            <NavLink to="#" style={{ color: "inherit" }}>
              <Twitter sx={{ fontSize: 20, color: "#fff" }} />
            </NavLink>
            <NavLink to="#" style={{ color: "inherit" }}>
              <Instagram sx={{ fontSize: 20, color: "#fff" }} />
            </NavLink>
            <NavLink to="#" style={{ color: "inherit" }}>
              <YouTube sx={{ fontSize: 20, color: "#fff" }} />
            </NavLink>
          </Box>
        </Grid>

        {/* Quick Links */}
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom>
            Quick Links
          </Typography>
          <Box display="flex" flexDirection="column" gap={1}>
            <NavLink
              to="/"
              style={{ color: "inherit", textDecoration: "none" }}>
              Home
            </NavLink>
            <NavLink
              to="/shop"
              style={{ color: "inherit", textDecoration: "none" }}>
              Shop
            </NavLink>
            <NavLink
              to="/about"
              style={{ color: "inherit", textDecoration: "none" }}>
              About Us
            </NavLink>
            <NavLink
              to="/contact"
              style={{ color: "inherit", textDecoration: "none" }}>
              Contact
            </NavLink>
            <NavLink
              to="/blog"
              style={{ color: "inherit", textDecoration: "none" }}>
              Blog
            </NavLink>
          </Box>
        </Grid>

        {/* Customer Service */}
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom>
            Customer Service
          </Typography>
          <Box display="flex" flexDirection="column" gap={1}>
            <NavLink
              to="/account"
              style={{ color: "inherit", textDecoration: "none" }}>
              My Account
            </NavLink>
            <NavLink
              to="/order-tracking"
              style={{ color: "inherit", textDecoration: "none" }}>
              Order Tracking
            </NavLink>
            <NavLink
              to="/wishlist"
              style={{ color: "inherit", textDecoration: "none" }}>
              Wishlist
            </NavLink>
            <NavLink
              to="/returns"
              style={{ color: "inherit", textDecoration: "none" }}>
              Returns & Exchanges
            </NavLink>
            <NavLink
              to="/faqs"
              style={{ color: "inherit", textDecoration: "none" }}>
              FAQs
            </NavLink>
          </Box>
        </Grid>

        {/* Newsletter Signup */}
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom>
            Stay Updated
          </Typography>
          <Typography variant="body2" paragraph>
            Subscribe to our newsletter for exclusive deals and auto care tips.
          </Typography>
          <Box component="form" display="flex" flexDirection="column" gap={2}>
            <TextField
              type="email"
              placeholder="Your email address"
              variant="outlined"
              size="small"
              InputProps={{
                style: { backgroundColor: "#333", color: "#fff" },
              }}
            />
            <Button
              variant="contained"
              sx={{ backgroundColor: "#5271ff" }}
              startIcon={<Mail />}>
              Subscribe
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* Divider */}
      <Divider sx={{ my: 4, backgroundColor: "#444" }} />

      {/* Bottom Footer */}
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="body2" textAlign={{ xs: "center", md: "left" }}>
          © 2024 TurboTech AutoParts. All rights reserved.
        </Typography>
        <Box display="flex" flexWrap="wrap" gap={2} justifyContent="center">
          <NavLink
            to="/privacy-policy"
            style={{ color: "inherit", textDecoration: "none" }}>
            Privacy Policy
          </NavLink>
          <NavLink
            to="/terms-of-service"
            style={{ color: "inherit", textDecoration: "none" }}>
            Terms of Service
          </NavLink>
          <NavLink
            to="/shipping-info"
            style={{ color: "inherit", textDecoration: "none" }}>
            Shipping Info
          </NavLink>
        </Box>
      </Box>
    </footer>
  );
}
