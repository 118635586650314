/** @format */

import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Select,
  MenuItem,
  Button,
  Box,
  Grid,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import categoriesData from "../../data/categories.json";
import productsData from "../../data/products.json";

const MakeModelYearVertical = () => {
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [year, setYear] = useState("");
  const [models, setModels] = useState([]);
  const [years, setYears] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (make) {
      // Extract unique models for the selected make
      const uniqueModels = [
        ...new Set(
          productsData
            .filter((product) => product.make === make)
            .map((p) => p.model)
        ),
      ];
      setModels(uniqueModels);
      setModel(""); // Clear model when make is changed
      setYear(""); // Clear year when make is changed
      setYears([]); // Clear year options when make is changed
    } else {
      setModels([]);
      setModel("");
      setYear("");
      setYears([]);
    }
  }, [make]);

  useEffect(() => {
    if (model) {
      // Extract unique years for the selected model
      const uniqueYears = [
        ...new Set(
          productsData
            .filter((product) => product.model === model)
            .map((p) => p.year)
        ),
      ];
      setYears(uniqueYears);
    } else {
      setYear("");
      setYears([]);
    }
  }, [model]);

  const handleSearch = () => {
    // Redirect to Shop page with query parameters
    navigate(`/shop?make=${make}&model=${model}&year=${year}`);
  };

  const handleClear = () => {
    setMake("");
    setModel("");
    setYear("");
    setModels([]);
    setYears([]);
  };

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Typography variant="h5" sx={{ textAlign: "left", mb: 2 }}>
        Find Your Vehicle
      </Typography>
      <Grid container direction="column" spacing={1}>
        {/* Make Field */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Select
              labelId="make-select-label"
              value={make}
              onChange={(e) => setMake(e.target.value)}
              displayEmpty
              fullWidth
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "4px",
                boxShadow: "none",
                "& .MuiSelect-select": {
                  padding: "8px", // Minimal padding
                },
                "& .MuiInputBase-root": {
                  borderRadius: "4px",
                },
              }}>
              <MenuItem value="" disabled>
                Select Make
              </MenuItem>
              {categoriesData.map((category) => (
                <MenuItem key={category.id} value={category.name}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {/* Model Field */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Select
              labelId="model-select-label"
              value={model}
              onChange={(e) => setModel(e.target.value)}
              displayEmpty
              fullWidth
              disabled={!make}
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "4px",
                boxShadow: "none",
                "& .MuiSelect-select": {
                  padding: "8px", // Minimal padding
                },
                "& .MuiInputBase-root": {
                  borderRadius: "4px",
                },
              }}>
              <MenuItem value="" disabled>
                Select Model
              </MenuItem>
              {models.map((m) => (
                <MenuItem key={m} value={m}>
                  {m}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {/* Year Field */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Select
              labelId="year-select-label"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              displayEmpty
              fullWidth
              disabled={!model}
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "4px",
                boxShadow: "none",
                "& .MuiSelect-select": {
                  padding: "8px", // Minimal padding
                },
                "& .MuiInputBase-root": {
                  borderRadius: "4px",
                },
              }}>
              <MenuItem value="" disabled>
                Select Year
              </MenuItem>
              {years.map((y) => (
                <MenuItem key={y} value={y}>
                  {y}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box sx={{ textAlign: "left", mt: 2 }}>
        <Button
          variant="contained"
          onClick={handleSearch}
          disabled={!year}
          sx={{
            bgcolor: "#5271ff",
            color: "#fff",
            borderRadius: "4px",
            px: 2, // Minimal padding
            py: 1, // Minimal padding
            "&:hover": {
              bgcolor: "#5271ff",
            },
            boxShadow: "none",
            mr: 1, // Margin to separate buttons
          }}>
          Find Product
        </Button>
        <Button
          variant="outlined"
          onClick={handleClear}
          sx={{
            borderColor: "#5271ff",
            color: "#5271ff",
            borderRadius: "4px",
            px: 2, // Minimal padding
            py: 1, // Minimal padding
            "&:hover": {
              borderColor: "#5271ff",
              color: "#5271ff",
            },
            boxShadow: "none",
          }}>
          Clear
        </Button>
      </Box>
    </Box>
  );
};

export default MakeModelYearVertical;
