/** @format */

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { CartProvider } from "./components/CartContext";
import { ToastContainer } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";

import { Elements } from "@stripe/react-stripe-js";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
const stripePromise = loadStripe(
  "pk_test_51Pw99QJ5eHYvRoN6BZd24jhznab2VpVrsxo0oG4dbHyzZGhK9OSC2j6ZhON9osWwlw5bpT2S0P1YeZQ2pI4KHY9a00JHQXOaAa"
);

root.render(
  <React.StrictMode>
    <CartProvider>
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
      <ToastContainer />
    </CartProvider>
  </React.StrictMode>
);
