/** @format */

import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Box,
  Container,
} from "@mui/material";
import {
  Star as StarIcon,
  ShoppingCart as ShoppingCartIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import productsData from "../data/products.json";
import Trusted from "../components/Home/Trusted";
import Services from "../components/Home/Services";
import HeroSection from "../components/HeroSection/HeroSection";

export default function Home() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    // Fetch or filter products data as needed
    setProducts(productsData); // Set the products data
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "#ffffff",
      }}>
      <main style={{ flex: 1 }}>
        <HeroSection />

        <section style={{ padding: "48px", backgroundColor: "#ffffff" }}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardMedia
                    component="img"
                    alt="Mechanic working on a car engine"
                    height="310"
                    sx={{ objectFit: "cover" }}
                    image="https://sidecarmirrors.com.au/wp-content/uploads/2024/06/iStock-1279877193.jpg"
                  />
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <CardContent>
                  <Typography variant="h4" gutterBottom>
                    Quality parts and service you can trust
                  </Typography>
                  <Typography variant="body1" color="textSecondary" paragraph>
                    At TurboTech AutoParts, we're passionate about delivering
                    top-quality parts to keep your vehicle running at its best.
                    With over 20 years of experience, our team of experts
                    carefully selects each product to ensure reliability,
                    performance, and value for our customers.
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#5271ff" }}>
                    Read Our Story
                  </Button>
                </CardContent>
              </Grid>
            </Grid>
          </Container>
        </section>

        <section style={{ padding: "48px 0", backgroundColor: "#fff" }}>
          <Container>
            <Typography variant="h3" component="h3" gutterBottom align="center">
              Featured Products
            </Typography>
            <Grid container spacing={4} justifyContent="center">
              {products.length ? (
                products.slice(0, 3).map((product) => (
                  <Grid item xs={12} sm={6} md={4} key={product.id}>
                    <Card>
                      <CardMedia
                        component="img"
                        alt={product.name}
                        height="240"
                        image={
                          product.image ||
                          "/placeholder.svg?height=240&width=360"
                        }
                      />
                      <CardContent>
                        <Typography variant="h6" component="h3" gutterBottom>
                          {product.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          paragraph>
                          {product.description}
                        </Typography>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center">
                          <Typography variant="h6" sx={{ color: "#5271ff" }}>
                            ${product.price}
                          </Typography>
                          <Button
                            variant="contained"
                            sx={{ backgroundColor: "#5271ff" }}
                            size="small"
                            component={Link}
                            to={`/product/${product.id}`}>
                            <ShoppingCartIcon style={{ marginRight: 8 }} />
                            View Details
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              ) : (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6">No products found</Typography>
                </Box>
              )}
            </Grid>
          </Container>
        </section>

        <Services />
        <Trusted />
      </main>
    </div>
  );
}
