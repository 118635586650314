/** @format */

import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Container,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Formspree endpoint
    const response = await fetch("https://formspree.io/f/mdknjlbd", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    setIsSubmitting(false);

    if (response.ok) {
      alert("Message sent successfully!");
      setFormData({ name: "", email: "", subject: "", message: "" });
    } else {
      alert("Failed to send message. Please try again.");
    }
  };

  return (
    <Wrapper>
      <Container>
        <Typography variant="h2" align="center" gutterBottom>
          Contact Us
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ padding: "2rem", borderRadius: "1rem" }}>
              <Typography variant="h6" gutterBottom>
                Get in Touch
              </Typography>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3401.8130161117037!2d74.36220507506857!3d31.50182304805918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391905b42a870a6f%3A0x76eb96d7139022c4!2siStore%20Cavalry%20Ground%20(Apple%20Authorised%20Reseller)!5e0!3m2!1sen!2s!4v1725567241579!5m2!1sen!2s"
                width="100%"
                height="300"
                style={{ border: 0, borderRadius: "8px" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ padding: "2rem", borderRadius: "1rem" }}>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                method="POST"
                onSubmit={handleSubmit}
                sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                <Typography variant="h3" gutterBottom>
                  Send Us a Message
                </Typography>
                <TextField
                  fullWidth
                  label="Name"
                  variant="outlined"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  sx={{ "& .MuiOutlinedInput-root": { borderRadius: "10px" } }}
                />
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  sx={{ "& .MuiOutlinedInput-root": { borderRadius: "10px" } }}
                />
                <TextField
                  fullWidth
                  label="Subject"
                  variant="outlined"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  sx={{ "& .MuiOutlinedInput-root": { borderRadius: "10px" } }}
                />
                <TextField
                  fullWidth
                  label="Message"
                  variant="outlined"
                  name="message"
                  multiline
                  rows={4}
                  value={formData.message}
                  onChange={handleChange}
                  sx={{ "& .MuiOutlinedInput-root": { borderRadius: "10px" } }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{
                    mt: 2,
                    borderRadius: "10px",
                    backgroundColor: "#5271ff",
                    "&:hover": {
                      backgroundColor: "#4056d0",
                    },
                  }}
                  disabled={isSubmitting}>
                  {isSubmitting ? "Sending..." : "Send Message"}
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};

// Styled component for the main section
const Wrapper = styled("section")({
  padding: "5rem 0 5rem 0",
  textAlign: "center",
  backgroundColor: "#f8f8ff",
  overflow: "hidden",
  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(135deg, rgba(82, 113, 255, 0.1), rgba(82, 113, 255, 0.1))",
    zIndex: -1,
    transform: "scale(1.5)",
    opacity: 0.2,
  },
});

export default Contact;
