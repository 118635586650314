/** @format */

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Grid,
  Typography,
  Box,
  Button,
  Paper,
  Divider,
  Checkbox,
  FormControlLabel,
  IconButton,
  Drawer,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import ProductCard from "../components/Products/ProductCard";
import productsData from "../data/products.json";
import categoriesData from "../data/categories.json";
import MakeModelYearHorizontal from "../components/MMY/MakeModelYearHorizontal";

const Shop = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const make = query.get("make");
  const model = query.get("model");
  const year = query.get("year");

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    let results = productsData;

    if (make) results = results.filter((p) => p.make === make);
    if (model) results = results.filter((p) => p.model === model);
    if (year) results = results.filter((p) => p.year === year);
    if (selectedCategories.length)
      results = results.filter((p) => selectedCategories.includes(p.category));

    setFilteredProducts(results);
  }, [make, model, year, selectedCategories]);

  useEffect(() => {
    setCategories(categoriesData);
  }, []);

  const handleCategoryChange = (event) => {
    const category = event.target.name;
    setSelectedCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ padding: { xs: 1, md: 3 }, borderRadius: 2 }}>
        <Typography variant="h4" gutterBottom align="center">
          Shop
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 3,
          }}>
          {/* Filter Drawer for small screens */}
          <IconButton
            color="primary"
            onClick={toggleDrawer(true)}
            sx={{
              display: { xs: "block", md: "none" },
              alignSelf: "flex-start",
            }}>
            <FilterListIcon />
          </IconButton>
          <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
            <Paper sx={{ width: 250, padding: 2 }}>
              <Typography variant="h6" gutterBottom>
                Filters
              </Typography>
              <MakeModelYearHorizontal />
              <Divider />
              <Box sx={{ marginY: 2 }}>
                <Typography variant="subtitle1">Category</Typography>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {categories.map((category) => (
                    <FormControlLabel
                      key={category.id}
                      control={
                        <Checkbox
                          checked={selectedCategories.includes(category.name)}
                          onChange={handleCategoryChange}
                          name={category.name}
                        />
                      }
                      label={category.name}
                      sx={{ marginY: 1 }}
                    />
                  ))}
                </Box>
              </Box>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#5271ff",
                  "&:hover": { backgroundColor: "#5271ffee" },
                }}
                fullWidth
                onClick={() => setFilteredProducts(filteredProducts)}>
                Apply Filters
              </Button>
            </Paper>
          </Drawer>

          {/* Filter Sidebar for larger screens */}
          <Paper
            sx={{
              width: { xs: "100%", md: 350 },
              padding: 2,
              display: { xs: "none", md: "block" },
              position: "sticky",
              top: 100,
              boxShadow: 3,
            }}>
            <Typography variant="h6" gutterBottom>
              Filters
            </Typography>
            <MakeModelYearHorizontal />
            <Divider />
            <Box sx={{ marginY: 2 }}>
              <Typography variant="subtitle1">Category</Typography>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {categories.map((category) => (
                  <FormControlLabel
                    key={category.id}
                    control={
                      <Checkbox
                        checked={selectedCategories.includes(category.name)}
                        onChange={handleCategoryChange}
                        name={category.name}
                      />
                    }
                    label={category.name}
                    sx={{ marginY: 1 }}
                  />
                ))}
              </Box>
            </Box>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#5271ff",
                "&:hover": { backgroundColor: "#5271ffee" },
              }}
              fullWidth
              onClick={() => setFilteredProducts(filteredProducts)}>
              Apply Filters
            </Button>
          </Paper>

          {/* Product Display */}
          <Box sx={{ flexGrow: 1 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 2,
              }}>
              <Typography variant="h6">Products</Typography>
            </Box>

            <Grid container spacing={2}>
              {filteredProducts.length ? (
                filteredProducts.map((product) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
                    <ProductCard product={product} />
                  </Grid>
                ))
              ) : (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6">No products found</Typography>
                </Box>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Shop;
