/** @format */

import React, { useState } from "react";
import { useAuth } from "../components/AuthContext";
import { NavLink, useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Link,
  Avatar,
  Grid,
  Paper,
  CssBaseline,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const useStyles = {
  root: {
    height: "100vh",
  },
  paper: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    maxWidth: 400,
  },
  avatar: {
    margin: 10,
    backgroundColor: "#3f51b5",
  },
  form: {
    width: "100%",
    marginTop: 20,
  },
  submit: {
    marginTop: 20,
  },
  link: {
    marginTop: 10,
  },
};

const Login = () => {
  const classes = useStyles;
  const { login, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    const userData = { email }; // Simplified user data
    login(userData);
    navigate("/cart");
  };

  return (
    <Grid container component="main" sx={classes.root}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage:
            "url('https://img.freepik.com/free-photo/view-three-dimensional-car_23-2150998585.jpg?t=st=1725659808~exp=1725663408~hmac=6da601f1e1336ae68930c336b39cd1a9c2591ddf1fcc77548dedca28402bbdbf&w=740')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div style={classes.paper}>
          <Avatar style={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <form style={classes.form} noValidate>
            <TextField
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              margin="normal"
              autoComplete="email"
              required
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              margin="normal"
              autoComplete="current-password"
              required
            />
            <Button
              variant="contained"
              sx={{ bgcolor: "#5271ff" }}
              fullWidth
              onClick={handleLogin}
              style={classes.submit}>
              Login
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <NavLink
                  to="/register"
                  variant="body2"
                  style={{ color: "#5271ff" }}>
                  Don’t have an account? Sign up
                </NavLink>
                {/* <Link href="/register" variant="body2" style={classes.link}>
                  Don’t have an account? Sign up
                </Link> */}
              </Grid>
            </Grid>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default Login;
