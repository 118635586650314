/** @format */

import React from "react";
import { NavLink } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Badge,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useCart } from "../CartContext"; // Import useCart

const Nav = ({ open, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { cartItems } = useCart(); // Get cartItems from context

  // Calculate total items in cart
  const totalItems = cartItems.reduce((sum, item) => sum + item.quantity, 0);

  // Only show this component on mobile devices
  if (!isMobile) return null;

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: "250px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
          },
        }}>
        <IconButton onClick={onClose} sx={{ alignSelf: "flex-end" }}>
          <CloseIcon />
        </IconButton>
        <List>
          {/* Use consistent routes with the header */}
          {[
            { text: "Home", to: "/" },
            { text: "Products", to: "/shop" },
            { text: "About", to: "/about" },
            { text: "Contact", to: "/contact" },
          ].map(({ text, to }) => (
            <ListItem
              button
              component={NavLink}
              to={to}
              key={text}
              onClick={onClose}>
              <ListItemText primary={text} />
            </ListItem>
          ))}
          <ListItem button component={NavLink} to="/cart">
            <Badge badgeContent={totalItems} color="secondary">
              <ShoppingCartIcon />
            </Badge>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default Nav;
