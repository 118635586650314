/** @format */

import React, { useEffect, useState } from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import { useLocation } from "react-router-dom";

const ThankYouPage = () => {
  const [sessionDetails, setSessionDetails] = useState(null);
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sessionId = params.get("session_id");

    fetch(`/session-status?session_id=${sessionId}`)
      .then((res) => res.json())
      .then((data) => {
        setSessionDetails(data);
        setInvoiceUrl(data.invoiceUrl);
      });
  }, [location.search]);

  return (
    <Container
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 4,
      }}>
      <Box
        sx={{
          padding: 4,
          textAlign: "center",
          color: "#000",
          maxWidth: 600,
          width: "100%",
          backgroundColor: "#fff",
          borderRadius: 2,
          boxShadow: 3,
        }}>
        <Typography
          variant="h3"
          sx={{
            marginBottom: 2,
            color: "#5271ff",
          }}>
          Thank You for Your Purchase!
        </Typography>
        {sessionDetails && (
          <>
            <Typography variant="h6">
              A confirmation email has been sent to{" "}
              {sessionDetails.customer_email}.
            </Typography>
            {invoiceUrl && (
              <Button
                variant="contained"
                color="primary"
                href={invoiceUrl}
                download
                sx={{ marginTop: 2 }}>
                Download Invoice
              </Button>
            )}
          </>
        )}
      </Box>
    </Container>
  );
};

export default ThankYouPage;
