/** @format */

import React from "react";
import { Box, Grid, Typography, Card, CardContent } from "@mui/material";
import { styled } from "@mui/system";
import {
  FastForward,
  LocalShipping,
  MoneyOff,
  Security,
} from "@mui/icons-material"; // Icons from Material UI

const servicesData = [
  {
    title: "Super Fast and Free Delivery",
    description:
      "Enjoy lightning-fast delivery services with no additional costs. We ensure your orders arrive on time, every time!",
    icon: <FastForward sx={{ fontSize: 50, color: "#5271ff" }} />,
  },
  {
    title: "Non-contact Shipping",
    description:
      "Our non-contact shipping ensures the safety of your orders, following strict protocols for a seamless and safe delivery.",
    icon: <LocalShipping sx={{ fontSize: 50, color: "#5271ff" }} />,
  },
  {
    title: "Money Back Guaranteed",
    description:
      "We offer a no-questions-asked money-back guarantee to ensure your satisfaction and peace of mind with every purchase.",
    icon: <MoneyOff sx={{ fontSize: 50, color: "#5271ff" }} />,
  },
  {
    title: "Super Secure Payment System",
    description:
      "Your transactions are protected with the most advanced encryption and security technologies, ensuring your data is safe.",
    icon: <Security sx={{ fontSize: 50, color: "#5271ff" }} />,
  },
];

const Services = () => {
  return (
    <Box sx={{ py: 5, px: 3, backgroundColor: "#f9f9f9", pt: 10, pb: 10 }}>
      <Grid container spacing={4}>
        {servicesData.map((service, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <ServiceCard>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "space-between",
                  height: "100%", // Full height to ensure uniformity
                }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: 2,
                  }}>
                  <Box sx={{ mr: 2 }}>{service.icon}</Box>
                </Box>
                <Box>
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    gutterBottom
                    textAlign="center">
                    {service.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    textAlign="center">
                    {service.description}
                  </Typography>
                </Box>
              </CardContent>
            </ServiceCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

// Styled component for service cards with animated border
const ServiceCard = styled(Card)({
  backgroundColor: "#fff",
  padding: "1.5rem",
  borderRadius: "0.75rem",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  height: "75%", // Ensures all cards have the same height
  display: "flex", // Makes sure flexbox properties apply
  flexDirection: "column", // Ensures the content is stacked
  justifyContent: "space-between", // Pushes content evenly
  transition: "transform 0.3s, box-shadow 0.3s",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: "0 6px 15px rgba(0, 0, 0, 0.2)",
  },
  "&::before": {
    content: '""',
    position: "absolute",
    top: "-10px",
    left: "-10px",
    width: "calc(100% + 20px)",
    height: "calc(100% + 20px)",
    border: "2px solid #5271ff",
    borderRadius: "0.75rem",
    transition: "all 0.6s ease",
    zIndex: -1,
    animation: "animateBorder 2s infinite",
  },
  "@keyframes animateBorder": {
    "0%": {
      transform: "scale(1)",
      opacity: 1,
    },
    "50%": {
      transform: "scale(1.1)",
      opacity: 0.7,
    },
    "100%": {
      transform: "scale(1)",
      opacity: 1,
    },
  },
});

export default Services;
