/** @format */

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  Button,
  IconButton,
  Paper,
  Grid,
} from "@mui/material";
import {
  Star as StarIcon,
  ShoppingCart as ShoppingCartIcon,
  LocalShipping as LocalShippingIcon,
  Shield as ShieldIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";
import productsData from "../data/products.json";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCart } from "../components/CartContext"; // Import useCart

const SingleProductPage = () => {
  const { id } = useParams();
  const product = productsData.find((p) => p.id === id);
  const { addToCart } = useCart(); // Use addToCart from context

  const [quantity, setQuantity] = useState(1);

  const handleAddToCart = () => {
    if (product) {
      addToCart(product, quantity); // Add product to cart with quantity
      toast.success(`${product.name} added to cart!`);
    } else {
      toast.error("Product not found");
    }
  };

  if (!product) {
    return (
      <Container>
        <Typography variant="h5" align="center">
          Product not found
        </Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ py: 5 }}>
      {/* Breadcrumb */}
      <Box
        sx={{
          mb: 2,
          display: "flex",
          alignItems: "center",
          fontSize: "0.875rem",
          flexWrap: "wrap",
        }}>
        <Typography component="a" href="/" color="textSecondary">
          Home
        </Typography>
        <ExpandMoreIcon sx={{ mx: 1 }} />
        <Typography component="a" href="#" color="textSecondary">
          {product.category}
        </Typography>
        <ExpandMoreIcon sx={{ mx: 1 }} />
        <Typography color="textPrimary">{product.name}</Typography>
      </Box>

      <Grid container spacing={4}>
        {/* Product Images */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ position: "relative" }}>
            <img
              src={
                product.image ||
                "/placeholder.svg?height=600&width=600&text=Main+Product+Image"
              }
              alt={product.name}
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
              }}
            />
          </Paper>
        </Grid>

        {/* Product Info */}
        <Grid item xs={12} md={6}>
          <Typography variant="h4" component="h1" gutterBottom>
            {product.name}
          </Typography>
          <Typography variant="h5" sx={{ color: "#5271ff", mb: 2 }}>
            ${product.price || "N/A"}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {product.description}
          </Typography>

          {/* Quantity and Add to Cart */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                border: "1px solid",
                borderRadius: 1,
              }}>
              <IconButton
                onClick={() => setQuantity(Math.max(1, quantity - 1))}>
                <ExpandLessIcon />
              </IconButton>
              <Typography variant="body2" sx={{ px: 2 }}>
                {quantity}
              </Typography>
              <IconButton onClick={() => setQuantity(quantity + 1)}>
                <ExpandMoreIcon />
              </IconButton>
            </Box>
            <Button
              size="small"
              variant="contained"
              sx={{
                backgroundColor: "#5271ff",
                color: "white",
                "&:hover": {
                  backgroundColor: "#5271ffee",
                },
              }}
              startIcon={<ShoppingCartIcon />}
              onClick={handleAddToCart}>
              Add to Cart
            </Button>
          </Box>

          {/* Shipping and Warranty Info */}
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="body2"
              color="success.main"
              sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <LocalShippingIcon />
              Free shipping on orders over $100
            </Typography>
            <Typography
              variant="body2"
              sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <ShieldIcon />
              1-year warranty included
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SingleProductPage;
