/** @format */

import React from "react";
import { Box, Button, Grid, Typography, Container } from "@mui/material";
import { styled } from "@mui/system";
import MakeModelYear from "../MMY/MakeModelYear";
import HeroImge from "../../assets/imgs/HeroSection.jpg";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#5271ff",
  color: "#fff",
  padding: "12px 24px",
  fontSize: "1.6rem",
  fontWeight: "bold",
  textTransform: "capitalize",
  borderRadius: "0.5rem",
  transition: "background-color 0.3s, transform 0.2s",
  "&:hover": {
    backgroundColor: "#4058cc",
    transform: "scale(1.05)",
  },
  // Adjust padding and font size on small screens
  [theme.breakpoints.down("sm")]: {
    padding: "10px 20px",
    fontSize: "1.2rem",
  },
}));

const StyledFigure = styled(Box)(({ theme }) => ({
  margin: 0,
  position: "relative",
  "&::after": {
    content: '""',
    width: "60%",
    height: "80%",
    backgroundColor: "rgba(81, 56, 238, 0.4)",
    position: "absolute",
    left: "50%",
    top: "-5rem",
    transform: "translateX(-50%)",
    zIndex: -1,
    borderRadius: "1rem",
  },
  // Adjust width and positioning on smaller screens
  [theme.breakpoints.down("sm")]: {
    "&::after": {
      width: "80%",
      top: "-2rem",
    },
  },
}));

const HeroSection = () => {
  return (
    <Box>
      <section
        style={{
          position: "relative",
          padding: "48px 0",
          height: 500,
          backgroundImage: `url(${HeroImge})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          overflow: "hidden",
        }}>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Black overlay with 50% opacity
            zIndex: 1,
          }}
        />
        <Container
          style={{
            position: "relative",
            zIndex: 2,
            color: "#ffffff",
            textAlign: "center",
          }}>
          <Box>
            <Typography
              variant="h6"
              component="h1"
              sx={{ color: "#5271ff", fontWeight: "bold" }}
              gutterBottom>
              Welcome to{" "}
            </Typography>
            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              sx={{
                fontSize: { xs: "2.2rem", sm: "3rem", md: "4rem" }, // Responsive font sizes
              }}>
              Side Car Mirror Replacement Experts
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{
                fontSize: { xs: "1rem", sm: "1.2rem", md: "1.5rem" }, // Responsive body text
              }}>
              Premium auto parts for peak performance. Unleash your vehicle's
              true potential.
            </Typography>
            <div></div>
            <MakeModelYear />
          </Box>
        </Container>
      </section>
    </Box>
  );
};

export default HeroSection;
