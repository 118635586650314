/** @format */

import React, { useContext } from "react";
import HeroSection from "../components/HeroSection/HeroSection";

const About = () => {
  const data = {
    name: "Car Side Mirror New",
  };

  return (
    <>
      <HeroSection myData={data} />;
    </>
  );
};

export default About;
