/** @format */

import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardMedia,
  CardActions,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";

const ProductCard = ({ product }) => (
  <Card
    sx={{
      maxWidth: 345,
      boxShadow: 3,
      borderRadius: 2,
      transition: "transform 0.3s ease-in-out",
      "&:hover": {
        transform: "scale(1.05)",
      },
    }}>
    <CardMedia
      component="img"
      image={product.image || "/placeholder.svg"}
      alt={product.name}
      sx={{
        height: 200,
        objectFit: "cover",
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
      }}
    />
    <CardContent>
      <Typography gutterBottom variant="h6" component="div">
        {product.name}
      </Typography>
      <Typography variant="h6" sx={{ color: "#5271ff" }}>
        ${product.price}
      </Typography>
      <Box sx={{ mt: 1 }}>
        <Typography variant="body2" color="text.primary">
          {product.description}
        </Typography>
      </Box>
    </CardContent>
    <CardActions>
      <Button
        size="small"
        variant="contained"
        sx={{
          backgroundColor: "#5271ff",
          color: "white",
          "&:hover": {
            backgroundColor: "#5271ffee",
          },
        }}
        component={Link}
        to={`/product/${product.id}`}>
        View Details
      </Button>
    </CardActions>
  </Card>
);

export default ProductCard;
